import { Box, Collapse, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';

import {
  DownloadIcon,
  /* InstagramIcon,  */ RedditIcon,
  ReelsIcon,
  TikTokIcon,
} from '../../../../common/assets/shareIcons';
import { DownloadVideoTextHelper } from './DownloadVideoTextHelper';
import { useTrackEvent } from '../../../../common/hooks/useTrackEvent';
import { EventNames } from '../../../../common/constants/constants';
import { DownloadType } from '../../types';
import { useThemeLayout } from '../../../../common/hooks/useThemeLayout';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StoryVideosApiModel } from '../../../../api/models/videos';
import tinycolor from 'tinycolor2';
import { useTypedSelector } from '../../../../store/store';

export interface UserVideosValues {
  items: StoryVideosApiModel[];
  totalPages: number;
}

export const useStyles = makeStyles<
  Theme,
  { isThemeLayoutEnabled?: boolean; isDarkTheme?: boolean; hoverColor: string; brandColor: string }
>((_: Theme) => ({
  btn: ({ hoverColor, brandColor, isDarkTheme, isThemeLayoutEnabled }) => ({
    '& p': {
      color: isThemeLayoutEnabled ? (isDarkTheme ? '#fff' : '#1A1A1A') : '#1A1A1A',
    },
    '&:hover': {
      background: brandColor,
      color: hoverColor,
      '& p': {
        color: hoverColor,
      },
    },
  }),
}));

interface DownloadButtonData {
  icon: React.ReactNode;
  event: string;
  text: string;
}

const downloadButtons: Record<DownloadType, DownloadButtonData> = {
  Instagram: { text: 'Instagram', icon: <TikTokIcon />, event: EventNames.SharePageInstagramDownload },
  TikTok: { text: 'TikTok', icon: <TikTokIcon />, event: EventNames.SharePageTikTokDownload },
  Reels: { text: 'Facebook Reels', icon: <ReelsIcon />, event: EventNames.SharePageReelsDownload },
  Reddit: { text: 'Reddit', icon: <RedditIcon />, event: EventNames.SharePageRedditDownload },
  Download: { text: 'Save To Device', icon: <DownloadIcon />, event: EventNames.SharePageRawDownload },
};

interface DownloadVideoProps {
  onDownloadClick: (downloadType: DownloadType) => void;
  downloadingType: DownloadType | null;
}

export const DownloadVideo: React.FC<DownloadVideoProps> = ({ onDownloadClick, downloadingType }) => {
  const [selectedDownloadType, setSelectedDownloadType] = useState<DownloadType>();
  const [showHowToDownload, setShowHowToDownload] = useState(false);
  const { trackEvent } = useTrackEvent();

  const { isThemeLayoutEnabled, themeTextColor, isDarkTheme } = useThemeLayout();

  const { accountAttributes } = useTypedSelector((state) => state.account);
  const brandColor = accountAttributes?.properties?.['webapp.config']?.['primary-color'] || '';
  const textColor = tinycolor(brandColor).isDark() ? 'white' : 'black';
  const styles = useStyles({ hoverColor: textColor, brandColor, isThemeLayoutEnabled, isDarkTheme });

  return (
    <Box>
      <Typography
        style={{
          fontSize: '18px',
          fontWeight: '600',
          color: isThemeLayoutEnabled ? (isDarkTheme ? '#fff' : 'currentcolor') : 'currentcolor',
        }}
      >
        Where do you intend to post?
      </Typography>
      <Collapse in={true} timeout="auto" unmountOnExit>
        {!showHowToDownload ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="20px"
            color={isThemeLayoutEnabled ? themeTextColor : undefined}
          >
            <Box mt="10px" display="flex" flexDirection={'column'} alignItems={'center'} width="100%">
              {Object.entries(downloadButtons).map(([type, { text, event }]) => {
                const isSelected = selectedDownloadType === type;
                const isLoading = downloadingType === type;

                return (
                  <LoadingButton
                    className={styles.btn}
                    variant="contained"
                    key={type}
                    sx={{
                      border: '1px solid #EAECF0',
                      boxShadow: 'none',
                      justifyContent: 'flex-start',
                      height: '44px',
                      minHeight: '44px',
                      width: '100%',
                      minWidth: '311px',
                      color: isThemeLayoutEnabled ? (isDarkTheme ? '#fff' : '#1A1A1A') : '#1A1A1A',
                      padding: '16px',
                      marginBottom: '12px',
                      background: isSelected
                        ? 'primary'
                        : isThemeLayoutEnabled
                        ? isDarkTheme
                          ? '#1A1A1A'
                          : '#fff'
                        : '#fff',
                    }}
                    onClick={() => {
                      if (isIOS && isSafari) {
                        setShowHowToDownload(true);
                      }
                      setSelectedDownloadType(type as DownloadType);
                      trackEvent({ action: event });
                      onDownloadClick(type as DownloadType);
                    }}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    {isLoading ? null : text}
                  </LoadingButton>
                );
              })}
            </Box>
          </Box>
        ) : (
          <>
            <LoadingButton
              size="small"
              onClick={() => {
                selectedDownloadType && onDownloadClick(selectedDownloadType as DownloadType);
              }}
              loading={downloadingType === selectedDownloadType}
              loadingPosition="end"
              variant="contained"
              style={{
                background: 'primary',
                width: '100%',
                textAlign: 'center',
                marginTop: '27px',
                marginBottom: '12px',
                minWidth: '311px',
              }}
            >
              {selectedDownloadType && downloadButtons[selectedDownloadType].text}
            </LoadingButton>
            <Box style={{ marginBottom: '80px' }}>
              <DownloadVideoTextHelper />
            </Box>
          </>
        )}
      </Collapse>
    </Box>
  );
};
