import { useAppDispatch, useTypedSelector } from '../../../store/store';
import {
  changeStep,
  createResumableUploadUrl,
  CreateStoryRequest,
  resetUploadingIterations,
  setIsUploadInPorgress,
  setUploadError,
  UploadStoryDetails,
  // uploadVideoAsFile,
} from '../../../store/slices/upload';
// import Upload from 'gcs-browser-upload';
import { Upload } from './Upload';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { EventNames } from '../../../common/constants/constants';
import {
  getCachedUploadSpeed,
  getChunkSizeFromUploadSpeed,
  getVideoPosterWithCloudinary,
  isUploadCompleted,
  MINCHUNKSIZE,
} from '../../../pages/VideoToolPage/utils';
import {
  getSyncUpdatedVideoUrl,
  getVideoAspectRatio,
  getVideoDuration,
  isVideoARCloseToTargetAR,
} from '../../utilities';
import { useOnResumableUploadProgress } from './useUploadProgress';
import { useAttributesFilterUrl, useLogoFilterEnabled } from '../useAttributesFilterUrl';
import { usePostStory } from '../usePostStory';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { UploadProcessStep } from '../../../store/storeModels';
import { DEFAULT_STORY_DETAILS } from '../../../api/constants';
import { setUploadMetrics } from '../../../store/slices/ui';
// import { isIOS, isSafari } from 'react-device-detect';

export interface VideoPos {
  x: number;
  y: number;
  width: number;
}

export interface HandleSubmitFileProps {
  file: File | null;
  details: UploadStoryDetails;
  isPublic: boolean;
  videoPos: VideoPos;
  shouldPostStory?: boolean;
  webappRecorded: boolean;
}

// const chunkSize = 15728640;

export const useHandleSubmitUploadedFile = (
  setStoryId: Dispatch<SetStateAction<string>>,
  isAthleticSolutionsClient: boolean,
  returnUrl: string,
) => {
  const dispatch = useAppDispatch();

  const attributesFilterUrl = useAttributesFilterUrl();
  const logoEnabled = useLogoFilterEnabled();
  const { id } = useTypedSelector((state) => state.me);
  const { mainCategory } = useTypedSelector((state) => state.stories);
  const { account } = useTypedSelector((state) => state.account);
  const logoSrc = account.logo;
  const { id: categoryId } = mainCategory || {};

  // const onUploadProgress = useOnUploadProgress();
  const onResumableUploadProgress = useOnResumableUploadProgress();
  const postStory = usePostStory(setStoryId);
  const { trackEvent } = useTrackEvent();

  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [resumeUrl, setResumeUrl] = useState<string>('');
  const [resumableUpload, setResumableUpload] = useState<Upload | null>();

  useEffect(() => {
    if (resumableUpload) {
      dispatch(setUploadMetrics({ speedMbs: resumableUpload.speed, chunkSize: resumableUpload.chunkSize }));
    }
  }, [resumableUpload?.speed, resumableUpload?.chunkSize]);

  const uploadSpeed = getCachedUploadSpeed();

  const restartUpload = async (propupload?: Upload, propfile?: File, propUrl?: string, shouldPostStory = false) => {
    const isUploadInProgress = localStorage.getItem('isUploadInProgress');
    if (isUploadInProgress) {
      return;
    }
    localStorage.setItem('isUploadInProgress', 'true');
    const localupload = propupload || resumableUpload;
    if (localupload && isUploadCompleted(localupload.id)) {
      return;
    }

    const localfile = propfile || resumeFile;
    const localurla = propUrl || resumeUrl;
    if (!localfile) return;
    let localurl = '';
    if (!localurla) {
      try {
        const res = await createResumableUploadUrl(localfile.type);
        if (!res) return;
        dispatch(changeStep(UploadProcessStep.VideoUploading));
        localurl = res.resumableUploadUrl;
        setResumeUrl(res.resumableUploadUrl);
      } catch (err) {
        trackEvent({
          action: EventNames.Creating_resumable_failure,
          accountId: account.id,
          errorMessage: JSON.stringify(err),
          userId: id,
          url: localurl.split('?')[0],
        });
        dispatch(setUploadError(true));
        localStorage.setItem('isUploadInProgress', '');
      }
    }
    localurl = localurl || localurla;
    let upload;
    if (!localupload) {
      upload = new Upload({
        id: crypto.randomUUID(),
        uploadUrl: localurl,
        file: localfile,
        // chunkSize: chunkSize,
        // contentType: localfile.type || 'application/octet-stream',
        onProgress: onResumableUploadProgress,
        trackEvent,
      });
      setResumableUpload(upload);
    }
    dispatch(setUploadError(false));
    const duration = await getVideoDuration(localfile);
    const videoAr = await getVideoAspectRatio(localfile);
    const shouldFill = !isVideoARCloseToTargetAR(videoAr);

    // window.addEventListener('online', () => {
    //   console.log('setting online event listener from restartUpload');
    //   restartUpload(localupload, localfile, localurl, true);
    // });
    try {
      // const response = await initialPost({ options: res });
      // console.log('response', response);
      try {
        if (localupload) await localupload.start();
        else if (upload) {
          await upload.start();
        }
      } catch (err) {
        //@ts-ignore
        if (err?.name !== 'UploadAlreadyFinishedError') {
          trackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
            url: localurl.split('?')[0],
          });
          dispatch(setUploadError(true));
          localStorage.setItem('isUploadInProgress', '');
        }
        return;
      }
      //remove listener
      // console.log('removing online event listener from restartUpload');
      window.removeEventListener('online', () => {
        // console.log('removing online event listener from restartUpload');
      });

      dispatch(setIsUploadInPorgress(false));
      localStorage.setItem('isUploadInProgress', '');
      trackEvent({ action: EventNames.Upload_Completed });
      const downloadUrl = localurl.split('?')[0];

      const url = getSyncUpdatedVideoUrl(
        localurl.split('?')[0],
        logoSrc && logoEnabled ? attributesFilterUrl : undefined,
        0,
        trackEvent,
        shouldFill,
      );
      const newThumbnailUrl = getVideoPosterWithCloudinary(url || '', trackEvent) || '';

      const urlWithoutLogo = getSyncUpdatedVideoUrl(downloadUrl, undefined, undefined, trackEvent);
      const altThumbnailUrl = getVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

      const story: CreateStoryRequest = {
        categoryId: categoryId || '',
        originalFileUrl: localurl.split('?')[0],
        url: url,
        thumbnailUrl: newThumbnailUrl,
        altThumbnailUrl,
        isPublic: true,
        duration,
        details: {
          ...DEFAULT_STORY_DETAILS(),
          ctaBtnLinkUrl: returnUrl,
        },
        webappRecorded: false,
      };
      if (!shouldPostStory) return story;

      await postStory(story);
      dispatch(resetUploadingIterations());
      return story;
    } catch (error) {
      trackEvent({
        action: EventNames.Upload_Failed,
        accountId: account.id,
        errorMessage: JSON.stringify(error),
        userId: id,
        url: localurl.split('?')[0],
      });
      dispatch(setUploadError(true));
      return undefined;
    }
  };

  const handleUploadedFile = async ({
    file,
    details,
    isPublic,
    videoPos,
    shouldPostStory,
    webappRecorded,
  }: HandleSubmitFileProps): Promise<CreateStoryRequest | undefined> => {
    if (!file) return;
    setResumeFile(file);
    dispatch(setIsUploadInPorgress(true));
    dispatch(setUploadError(false));
    const duration = await getVideoDuration(file);
    const videoAr = await getVideoAspectRatio(file);
    const shouldFill = !isVideoARCloseToTargetAR(videoAr);
    try {
      const res = await createResumableUploadUrl(file.type);
      if (!res) return;
      dispatch(changeStep(UploadProcessStep.VideoUploading));

      setResumeUrl(res.resumableUploadUrl);

      // const response = await initialPost({ options: res });
      // console.log('response', response);
      // const id = crypto.randomUUID();
      const chunkSize = uploadSpeed ? getChunkSizeFromUploadSpeed(uploadSpeed.speedMbps) : MINCHUNKSIZE;
      const upload = new Upload({
        id: crypto.randomUUID(),
        uploadUrl: res.resumableUploadUrl,
        file: file,
        initialChunkSize: chunkSize,
        // contentType: res.contentType || file.type || 'application/octet-stream',
        onProgress: onResumableUploadProgress,
        trackEvent,
      });
      setResumableUpload(upload);
      window.addEventListener('online', () => {
        // console.log('setting online event listener from handleUploadedFile');
        restartUpload(upload, file, res.resumableUploadUrl, true);
      });
      try {
        await upload.start();
      } catch (err) {
        console.log('error', err);
        //@ts-ignore
        if (['FileAlreadyUploadedError', 'UploadAlreadyFinishedError'].includes(err?.name) ? false : true) {
          trackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
            url: res.resumableUploadUrl.split('?')[0],
          });
          dispatch(setUploadError(true));
          localStorage.setItem('isUploadInProgress', '');
        }
        return;
      }

      window.removeEventListener('online', () => {
        // console.log('removing online event listener from useHandleSubmitUploadedFile');
      });
      dispatch(setIsUploadInPorgress(false));

      trackEvent({ action: EventNames.Upload_Completed });
      const downloadUrl = res.resumableUploadUrl.split('?')[0];
      const url = getSyncUpdatedVideoUrl(
        res.resumableUploadUrl.split('?')[0],
        logoSrc && logoEnabled ? attributesFilterUrl : undefined,
        videoPos.width,
        trackEvent,
        shouldFill,
      );

      //TODO: Below to worker
      const newThumbnailUrl = getVideoPosterWithCloudinary(url || '', trackEvent) || '';

      const urlWithoutLogo = getSyncUpdatedVideoUrl(downloadUrl, undefined, undefined, trackEvent);
      const altThumbnailUrl = getVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

      const story: CreateStoryRequest = {
        categoryId: categoryId || '',
        url: url,
        originalFileUrl: res.resumableUploadUrl.split('?')[0],
        thumbnailUrl: newThumbnailUrl,
        altThumbnailUrl,
        isPublic: isPublic,
        duration,
        details: {
          ...details,
          ctaBtnLinkUrl: isAthleticSolutionsClient ? returnUrl : details.ctaBtnLinkUrl || null,
        },
        webappRecorded,
      };

      if (!shouldPostStory) return story;

      await postStory(story);
      dispatch(resetUploadingIterations());
      return story;
    } catch (error) {
      // console.log('error caught here', error);
      trackEvent({
        action: EventNames.Upload_Failed,
        accountId: account.id,
        errorMessage: JSON.stringify(error),
        userId: id,
      });
      dispatch(setUploadError(true));
      localStorage.setItem('isUploadInProgress', '');
      return undefined;
    }
  };

  const handleSubmitVideo = async (
    blobUrl: string | undefined,
    details: UploadStoryDetails,
    isPublic: boolean,
    supportedMimeType: string,
    smsNotificationRef?: MutableRefObject<boolean | undefined>,
    webappRecorded?: boolean,
  ) => {
    console.log(supportedMimeType);
    if (!blobUrl) return;
    // Fetch the Blob from the Blob URL
    const response = await fetch(blobUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch Blob from URL: ${response.statusText}`);
    }
    const blob = await response.blob();

    // Convert Blob to File
    const file = new File([blob], 'filename', { type: blob.type });

    if (!file) return;
    setResumeFile(file);
    dispatch(setIsUploadInPorgress(true));
    dispatch(setUploadError(false));
    const duration = await getVideoDuration(file);
    const videoAr = await getVideoAspectRatio(file);
    const shouldFill = !isVideoARCloseToTargetAR(videoAr);

    try {
      const res = await createResumableUploadUrl(file.type);
      if (!res) return;
      dispatch(changeStep(UploadProcessStep.VideoUploading));

      setResumeUrl(res.resumableUploadUrl);

      // const response = await initialPost({ options: res });
      // console.log('response', response);
      // const id = crypto.randomUUID();

      const upload = new Upload({
        id: crypto.randomUUID(),
        uploadUrl: res.resumableUploadUrl,
        file: file,
        // chunkSize: chunkSize,
        // contentType: res.contentType || file.type || 'application/octet-stream',
        onProgress: onResumableUploadProgress,
        trackEvent,
      });
      setResumableUpload(upload);
      window.addEventListener('online', () => {
        console.log('setting online event listener from handleUploadedFile');
        restartUpload(upload, file, res.resumableUploadUrl, true);
      });
      try {
        await upload.start();
      } catch (err) {
        //@ts-ignore
        if (err?.name !== 'UploadAlreadyFinishedError') {
          trackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
            url: res.resumableUploadUrl.split('?')[0],
          });
          dispatch(setUploadError(true));
          localStorage.setItem('isUploadInProgress', '');
        }
        return;
      }
      window.removeEventListener('online', () => {
        console.log('removing online event listener from useHandleSubmitUploadedFile');
      });
      dispatch(setIsUploadInPorgress(false));

      trackEvent({ action: EventNames.Upload_Completed });
      const downloadUrl = res.resumableUploadUrl.split('?')[0];
      const url = getSyncUpdatedVideoUrl(
        res.resumableUploadUrl.split('?')[0],
        logoSrc && logoEnabled ? attributesFilterUrl : undefined,
        undefined,
        trackEvent,
        shouldFill,
      );

      //TODO: Below to worker
      const newThumbnailUrl = getVideoPosterWithCloudinary(url || '', trackEvent) || '';

      const urlWithoutLogo = getSyncUpdatedVideoUrl(downloadUrl, undefined, undefined, trackEvent);
      const altThumbnailUrl = getVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

      const story: CreateStoryRequest = {
        categoryId: categoryId || '',
        url: url,
        thumbnailUrl: newThumbnailUrl,
        altThumbnailUrl,
        isPublic: isPublic,
        duration,
        sendSMSNotification: smsNotificationRef?.current || false,
        details: {
          ...details,
          ctaBtnLinkUrl: isAthleticSolutionsClient ? returnUrl : details.ctaBtnLinkUrl || null,
        },
        webappRecorded,
      };

      await postStory(story);
      dispatch(resetUploadingIterations());
      return story;
    } catch (error) {
      trackEvent({
        action: EventNames.Upload_Failed,
        accountId: account.id,
        errorMessage: JSON.stringify(error),
        userId: id,
      });
      dispatch(setUploadError(true));
      localStorage.setItem('isUploadInProgress', '');
      return undefined;
    }
  };

  return { handleUploadedFile, handleSubmitVideo, postStory, restartUpload, resumableUpload };
};
