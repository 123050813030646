import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../common/components/PageContainer/PageContainer';
import { GlassContainer } from '../../common/components/GlassContainer/GlassContainer';
import { Box } from '@mui/material';
import { UploadProgressBar } from '../../common/components/UploadProgressBar/UploadProgressBar';
import { useStyles } from './VideoUploadingPage.styles';
// import { UploadIcon } from '../../common/assets/UploadIcon';
// import { UploadProgressStrings } from '../../common/localization/en';
import { useTypedSelector } from '../../store/store';
import { UploadErrorNotification } from '../../common/components/UploadErrorNotification/UploadErrorNotification';
// import { useThemeLayout } from '../../common/hooks/useThemeLayout';
import { IncentiveBlock } from '../../common/components/IncentiveBlock/IncentiveBlock';

const AVERAGE_UPLOAD_SPEED = 0.6; // Mb per second;

interface Props {
  onRestartClick: () => void;
}

export const VideoUploadingPage: React.FC<Props> = ({ onRestartClick }) => {
  const classes = useStyles();
  // const { isDarkTheme } = useThemeLayout();

  const { uploadingIterations, isUploadError } = useTypedSelector((state) => state.upload);

  const [isEstimationTimeCalculated, setIsEstimationTimeCalculated] = useState(false);
  const [, setEstimationTime] = useState(0);

  useEffect(() => {
    if (uploadingIterations.length > 0 && !isEstimationTimeCalculated) {
      const totalSize = uploadingIterations[0].total / 1024 / 1024;

      setEstimationTime(totalSize / AVERAGE_UPLOAD_SPEED);
      setIsEstimationTimeCalculated(true);
    }
  }, [isEstimationTimeCalculated, uploadingIterations]);

  // const minutes = Math.floor(estimationTime / 60);
  // const seconds = Math.ceil(estimationTime % 60);

  return (
    <PageContainer withOffsetTop={false}>
      <GlassContainer my="-30px" hideGlass={true}>
        <Box className={classes.contentWrapper}>
          <UploadProgressBar />
          {isUploadError ? (
            <Box className={classes.uploadNotificationWrapper}>
              <UploadErrorNotification onRestartClick={onRestartClick} />
            </Box>
          ) : (
            <>
              <IncentiveBlock showQuestions={true} />
            </>
          )}
        </Box>
      </GlassContainer>
    </PageContainer>
  );
};
