import React, { FC } from 'react';
import { useStyles } from './TermsOfUse.styles';
import { Box } from '@mui/system';
import { CustomCheckBox } from '../../../../common/components/CustomCheckBox/CustomCheckBox';
import { EventNames } from '../../../../common/constants/constants';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../../common/hooks/useTrackEvent';
import { Typography } from '@mui/material';
import { SignUpScreenStrings } from '../../../../common/localization/en';
import {
  PRIVACY_POLICY_LINK_TRUBLU,
  SV_USER_TERMS,
  TERMS_OF_SERVICE_LINK_TRUBLU,
} from '../../../../common/components/AuthForm/AuthForm.helper';

interface Props {
  isTermsAccepted: boolean | null;
  onChange: (isChecked: boolean | null) => void;
}

export const TermsOfUse: FC<Props> = ({ onChange, isTermsAccepted }) => {
  const classes = useStyles();
  const { trackEvent } = useTrackEvent();
  const { isTrubluClient } = useClientType();

  return (
    <Box>
      <Box className={classes.termsWrapper}>
        <CustomCheckBox
          isChecked={isTermsAccepted || false}
          onChange={(_, isChecked) => {
            onChange(isChecked);
            trackEvent({ action: EventNames.Sv_Tos_Checkbox_Click });
          }}
        />
        {isTrubluClient ? (
          <Typography display="inline" className={classes.termsText}>
            {SignUpScreenStrings.Accept}
            <a className={classes.termsLink} href={TERMS_OF_SERVICE_LINK_TRUBLU} target="_blank" rel="noreferrer">
              {SignUpScreenStrings.TermsOfUse}
            </a>
            {SignUpScreenStrings.Ampersand}
            <a className={classes.termsLink} href={PRIVACY_POLICY_LINK_TRUBLU} target="_blank" rel="noreferrer">
              {SignUpScreenStrings.PrivacyPolicy}
            </a>
          </Typography>
        ) : (
          <Typography display="inline" className={classes.termsText}>
            {SignUpScreenStrings.YouAgree}
            <a
              className={classes.termsLink}
              href={SV_USER_TERMS}
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
              }}
            >
              {SignUpScreenStrings.UserTerms}
            </a>
            {SignUpScreenStrings.Ampersand}
            <a
              className={classes.termsLink}
              href={SV_USER_TERMS}
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
              }}
            >
              {SignUpScreenStrings.PrivacyPolicy}
            </a>
          </Typography>
        )}
      </Box>
      {isTermsAccepted === false && (
        <Typography className={classes.termsError}>{SignUpScreenStrings.CheckTheBox}</Typography>
      )}

      {isTrubluClient && (
        <Box className={classes.svTermsWrapper}>
          <Typography display="inline" className={classes.termsText} textAlign="center">
            {SignUpScreenStrings.ByProceeding}
            <a
              className={classes.termsLink}
              href={SV_USER_TERMS}
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
              }}
            >
              {SignUpScreenStrings.UserTerms}
            </a>
            <br />
            {SignUpScreenStrings.Ampersand}
            <a
              className={classes.termsLink}
              href={SV_USER_TERMS}
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
              }}
            >
              {SignUpScreenStrings.PrivacyPolicy}
            </a>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
