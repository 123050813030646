import { Box, Grow, IconButton, Modal } from '@mui/material';
import React from 'react';
import { VideoPlayer, VideoPlayerProps } from '../../../common/components/VideoPlayer/VideoPlayer';
import { CloseIcon } from '../../../common/assets/playerIcons/CloseIcon';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { EventNames } from '../../../common/constants/constants';

interface VideoPreviewModalProps {
  src: VideoPlayerProps['src'];
  show: boolean;
  onClose: () => void;
  captionsUrl?: string;
}

export const VideoPreviewModal: React.FC<VideoPreviewModalProps> = ({ show, onClose, src, captionsUrl }) => {
  const { trackEvent } = useTrackEvent();

  return (
    <Modal
      open={show}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 99999 }}
    >
      {show ? (
        <Grow in={show} unmountOnExit>
          <Box width="245px" height="436px" position="relative">
            <VideoPlayer
              src={src}
              width="245px"
              height="436px"
              autoplay
              onClick={() => {
                trackEvent({ action: EventNames.MyVideosPagePlayVideo });
              }}
              captionsUrl={captionsUrl}
              showCaption
            />

            <IconButton
              sx={{
                position: 'absolute',
                top: '10px',
                right: '-35px',
                height: '25px',
                width: '25px',
                background: 'white',
                ':hover': {
                  transition: 'all ease 0.2s',
                  background: 'white',
                  scale: '1.1',
                },
              }}
              onClick={onClose}
            >
              <CloseIcon height="15px" width="15px" color="black" />
            </IconButton>
          </Box>
        </Grow>
      ) : (
        // Don't show closing animation
        <></>
      )}
    </Modal>
  );
};
