// hooks/useBackgroundUploadSpeedTest.ts
import { useEffect, useState } from 'react';
import { measureAverageUploadSpeed } from '../../pages/VideoToolPage/utils';
import { useTrackEvent } from './useTrackEvent';

export function useBackgroundUploadSpeedTest({
  sizeInBytes = 1 * 1024 * 1024,
  rounds = 3,
  autoStart = true,
}: {
  sizeInBytes?: number;
  rounds?: number;
  autoStart?: boolean;
}) {
  const [speedMbps, setSpeedMbps] = useState<number | null>(null);
  const [uploadTime, setUploadTime] = useState<number | null>(null);
  const [loading, setLoading] = useState(autoStart);
  const [error, setError] = useState<string | null>(null);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (!autoStart) return;

    let cancelled = false;

    const runTest = async () => {
      try {
        const result = await measureAverageUploadSpeed(sizeInBytes, rounds, undefined, trackEvent);
        if (!cancelled) {
          setSpeedMbps(result.speedMbps);
          setUploadTime(result.uploadTimeSeconds);
        }
      } catch (err) {
        if (!cancelled) {
          setError((err as Error).message);
        }
      } finally {
        if (!cancelled) {
          setLoading(false);
        }
      }
    };

    runTest();

    return () => {
      cancelled = true;
    };
  }, [autoStart, sizeInBytes, rounds]);

  return {
    speedMbps,
    uploadTime,
    loading,
    error,
  };
}
