import { CreateStory, CreateStoryRequest } from '../../store/slices/upload';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { Dispatch, SetStateAction } from 'react';
import { incrementStoriesCount } from '../../store/slices/stories';
import { getVideoPosterWithCloudinary } from '../../pages/VideoToolPage/utils';
import { updateUserRequest } from '../../store/slices/me';
// import { useUploadFileToStorage } from './useUploadFileToStorage';
// import { posterUrl, urlToFile } from '../utilities';
import { useTrackEvent } from '../../common/hooks/useTrackEvent';
import { EventNames } from '../../common/constants/constants';
import { useIsLoggedWithMagicLinkValue } from './useIsLoggedWithMagicLinkValue';

export const usePostStory = (setStoryId: Dispatch<SetStateAction<string>>) => {
  const dispatch = useAppDispatch();
  const { photoUrl: userPhotoUrl, email, accountId: useraccountId } = useTypedSelector((state) => state.me);
  const { trackEvent } = useTrackEvent();

  const [isLoggedWithMagicLink, updateLoggedWithMagicLinkValue] = useIsLoggedWithMagicLinkValue();

  // const { uploadFileToStorage } = useUploadFileToStorage();

  // const getStoryThumbnail = async (storyRequest: CreateStoryRequest) => {
  // Need to re-upload thumbnail image to get the ability to get it from local path using Cloudinary
  // const reuploadImage = async () => {
  //   try {
  //     await fetch(posterUrl(storyRequest.url));
  //     const thumbnailFile = await urlToFile(posterUrl(storyRequest.url), `story_thumbnail.jpg`, 'image/jpeg');

  //     const data = await uploadFileToStorage(thumbnailFile);
  //     if (!data || !data.downloadUrl) return null;

  //     return data.downloadUrl;
  //   } catch (error) {
  //     return null;
  //   }
  // };

  //   const thumbnail =  storyRequest.thumbnailUrl;

  //   return googleStorageImageUrlToCloudinaryUrl(thumbnail);
  // };

  const postStory = async (storyRequest: CreateStoryRequest) => {
    // const thumbnailUrl = await getStoryThumbnail(storyRequest);
    const campaignId = localStorage.getItem('campaignId');

    return dispatch(CreateStory({ ...storyRequest, campaignId })).then((data) => {
      if (!data.payload) {
        // something goes wrong
        console.error((data as any).error);
        return;
      }

      //@ts-ignore
      setStoryId(data.payload.id);
      dispatch(incrementStoriesCount());
      if (!userPhotoUrl) {
        // @ts-ignore
        const { url, userId } = data.payload;
        const poster = getVideoPosterWithCloudinary(url, trackEvent);
        dispatch(
          updateUserRequest({
            userId: userId || '',
            photoUrl: poster || '',
            accountId: useraccountId,
            email: email,
          }),
        );
      }
      if (isLoggedWithMagicLink) {
        updateLoggedWithMagicLinkValue();
      }

      trackEvent({ action: EventNames.Video_Created });
      return data;
    });
  };

  return postStory;
};
