import { EventNames } from '../../../common/constants/constants';
import { IEventTracker } from '../../../common/hooks/useTrackEvent';
import { getChunkSizeFromUploadSpeed } from '../../../pages/VideoToolPage/utils';

export interface UploadOptions {
  id: string; // Unique identifier for each upload session
  uploadUrl: string;
  file: File;
  initialChunkSize?: number;
  maxRetries?: number;
  retryDelay?: number;
  trackEvent: IEventTracker;
  onProgress?: (progress: {
    totalBytes: number;
    uploadedBytes: number;
    chunkIndex: number;
    chunkLength: number;
  }) => void;
}

export class Upload {
  public speed: number;
  public readonly id: string;
  private readonly uploadUrl: string;
  private uploadedBytes: number = 0;
  public chunkSize: number;
  private isPaused: boolean = false;
  private readonly file: File;
  private readonly maxRetries: number;
  private readonly retryDelay: number;
  private readonly onProgress?: (progress: {
    totalBytes: number;
    uploadedBytes: number;
    chunkIndex: number;
    chunkLength: number;
  }) => void;
  private readonly trackEvent: IEventTracker;

  constructor(options: UploadOptions) {
    this.speed = 1;
    this.id = options.id;
    this.uploadUrl = options.uploadUrl;
    this.file = options.file;
    this.chunkSize = options.initialChunkSize || 256 * 1024; // Default: 256KB
    this.maxRetries = options.maxRetries || 3;
    this.retryDelay = options.retryDelay || 2000;
    this.onProgress = options.onProgress;
    this.trackEvent = options.trackEvent;
  }

  /** Reads the upload data from localStorage */
  private static getStoredUploadData(): Record<string, any> {
    return JSON.parse(localStorage.getItem('uploadData') || '{}');
  }

  /** Saves this upload instance's data to localStorage */
  private saveUploadData(): void {
    const uploadData = Upload.getStoredUploadData();
    uploadData[this.id] = { uploadedBytes: this.uploadedBytes, chunkSize: this.chunkSize };
    localStorage.setItem('uploadData', JSON.stringify(uploadData));
  }

  /** Removes this upload instance's data from localStorage */
  private finalizeUpload(): void {
    const uploadData = Upload.getStoredUploadData();
    const uploadedEntry = uploadData[this.id];

    // Remove from uploadData
    delete uploadData[this.id];
    localStorage.setItem('uploadData', JSON.stringify(uploadData));

    // Move to uploadedData
    const uploadedData = JSON.parse(localStorage.getItem('uploadedData') || '{}');
    uploadedData[this.id] = {
      fileName: this.file.name,
      size: this.file.size,
      completedAt: new Date().toISOString(),
      ...uploadedEntry,
    };
    localStorage.setItem('uploadedData', JSON.stringify(uploadedData));
  }

  /** Starts or resumes an upload */
  async start(): Promise<void> {
    const uploadData = Upload.getStoredUploadData();
    this.uploadedBytes = uploadData[this.id]?.uploadedBytes || 0;
    this.uploadedBytes = await this.getUploadedBytes();
    this.saveUploadData();

    await this.uploadChunks();
  }

  /** Pauses the upload */
  pause(): void {
    this.isPaused = true;
    console.log(`Upload ${this.id} paused`);
  }

  /** Resumes the upload */
  async resume(): Promise<void> {
    if (this.isPaused) {
      this.isPaused = false;
      console.log(`Resuming upload ${this.id}...`);
      await this.uploadChunks();
    }
  }

  /** Gets the last uploaded byte offset */
  private async getUploadedBytes(): Promise<number> {
    const response = await fetch(this.uploadUrl, {
      method: 'PUT',
      headers: { 'Content-Range': `bytes */${this.file.size}` },
    });

    if (response.status === 308) {
      const rangeHeader = response.headers.get('Range');
      if (rangeHeader) {
        const match = rangeHeader.match(/bytes=(\d+)-(\d+)/);
        if (match) return parseInt(match[2], 10) + 1;
      }
    }

    return 0;
  }

  /** Uploads chunks until the file is fully uploaded */
  private async uploadChunks(): Promise<void> {
    let offset = this.uploadedBytes;
    let chunkIndex = Math.floor(this.uploadedBytes / this.chunkSize);

    while (offset < this.file.size && !this.isPaused) {
      const chunkEnd = Math.min(offset + this.chunkSize, this.file.size);
      const chunk = this.file.slice(offset, chunkEnd);

      try {
        await this.uploadChunk(offset, chunk, chunkEnd);
      } catch (error) {
        console.error(`Upload ${this.id} failed:`, error);
        throw error;
      }

      offset = chunkEnd;
      this.uploadedBytes = offset;
      this.saveUploadData();

      if (this.onProgress) {
        this.onProgress({
          totalBytes: this.file.size,
          uploadedBytes: this.uploadedBytes,
          chunkIndex,
          chunkLength: chunk.size,
        });
      }

      chunkIndex++;
    }

    if (offset >= this.file.size) {
      console.log(`Upload ${this.id} complete`);
      this.finalizeUpload();
    }
  }

  /** Uploads a single chunk with retries */
  private async uploadChunk(offset: number, chunk: Blob, chunkEnd: number, attempt = 1): Promise<void> {
    const startTime = performance.now();
    this.trackEvent({
      action: EventNames.upload_speed_test_initiated,
      location: window.location.href,
      chunkSize: chunk.size,
    });
    try {
      const response = await fetch(this.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Length': chunk.size.toString(),
          'Content-Range': `bytes ${offset}-${chunkEnd - 1}/${this.file.size}`,
        },
        body: chunk,
      });

      if (!response.ok && response.status !== 308) {
        throw new Error(`Failed chunk upload: ${response.status} - ${response.statusText}`);
      }

      // Measure upload speed and adjust chunk size
      const elapsedTime = (performance.now() - startTime) / 1000; // seconds
      const speedMbps = (chunk.size * 8) / (elapsedTime * 1_000_000);
      this.trackEvent({
        action: EventNames.upload_speed_test_completed,
        location: window.location.href,
        chunkSize: chunk.size,
        speedMbps,
        uploadTimeSeconds: elapsedTime,
      });
      this.speed = speedMbps;
      this.adjustChunkSize(speedMbps);
    } catch (error) {
      console.error(`Chunk upload error on attempt ${attempt} for upload ${this.id}:`, error);

      if (attempt < this.maxRetries) {
        console.warn(`Retrying chunk upload... Attempt ${attempt + 1}`);

        // Wait before retrying (exponential backoff)
        await new Promise((res) => setTimeout(res, this.retryDelay * attempt));

        return this.uploadChunk(offset, chunk, chunkEnd, attempt + 1);
      }

      console.error(`Max retries reached. Upload ${this.id} failed.`);
      throw new Error(`Upload failed after ${this.maxRetries} retries.`);
    }
  }

  /** Dynamically adjusts chunk size based on upload speed */
  private adjustChunkSize(speedMbps: number): void {
    const previousSize = this.chunkSize;
    this.chunkSize = getChunkSizeFromUploadSpeed(speedMbps);

    if (previousSize !== this.chunkSize) {
      console.log(`Chunk size updated to: ${this.chunkSize / 1024 / 1024} MB`);
    }
  }
}
