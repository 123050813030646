/* eslint-disable @next/next/no-img-element */
import { Box } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { getImageDimensionsToFit, getImageMetadata } from '../../../services/utilities';
import { useTypedSelector } from '../../../store/store';
import { EventNames, maxSizeUploadedUserFile } from '../../constants/constants';
import { useCreateVideoStyles } from './CreateVideo.styles';
import { isIOS } from 'react-device-detect';
import { ErrorsStrings } from '../../localization/en';
import { ErrorDialog } from '../ErrorDialog/ErrorDialog';

import { CreateVideoControls } from './CreateVideoControls/CreateVideoControls';
import { useTrackEvent } from '../../hooks/useTrackEvent';

interface CreateVideoProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
  setShowRecord: (value: boolean) => void;
  isButtonsDisabled: boolean;
}

export const CreateVideo: React.FC<CreateVideoProps> = ({ onFileChange, setShowRecord, isButtonsDisabled }) => {
  const { id: userId } = useTypedSelector((state) => state.me);
  const {
    account: { logo, id: accountId },
  } = useTypedSelector((state) => state.account);
  const { sidebarIsOpen } = useTypedSelector((state) => state.ui);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isFileSizeChecked, setIsFileSizeChecked] = useState(false);
  const [file, setFile] = useState<null | File>(null);
  const classes = useCreateVideoStyles({ clientHeight: window.innerHeight, isIos: isIOS });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [wasStoppedOnSidebarOpen, setWasStoppedOnSidebarOpen] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const { trackEvent } = useTrackEvent();

  const clearInput = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (refVideo.current) {
      const isVideoPaused = refVideo.current.paused;

      if (sidebarIsOpen && !isVideoPaused) {
        refVideo.current.pause();
        setWasStoppedOnSidebarOpen(true);
      }

      if (!sidebarIsOpen && isVideoPaused && wasStoppedOnSidebarOpen) {
        refVideo.current?.play();
        setWasStoppedOnSidebarOpen(false);
      }
    }
  }, [sidebarIsOpen, wasStoppedOnSidebarOpen]);

  useEffect(() => {
    if (file && file.size >= maxSizeUploadedUserFile) {
      setIsErrorAlert(true);
    }
    setIsFileSizeChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsErrorAlert, file]);

  useEffect(() => {
    if (file && !isErrorAlert && isFileSizeChecked && !isFileSelected) {
      onFileChange(file);
      setIsFileSelected(true);
    }
  }, [file, isErrorAlert, isFileSelected, isFileSizeChecked, onFileChange]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (!file) {
      clearInput();
      return;
    }
    trackEvent({
      action: EventNames.Create_Video_Page_file_selected,
      location: window.location.href,
      accountId,
      userId,
      fileSize: file.size,
      page: EventNames.Create_Video_Page,
    });
    setFile(file);
    setIsFileSizeChecked(false);
  };

  const resetVideo = () => {
    setIsErrorAlert(false);
    setFile(null);
    setIsFileSizeChecked(false);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isFileSelected) {
      setIsFileSelected(false);
      setFile(null);
      clearInput();
    }
    if (inputRef?.current && event.detail === 1) {
      inputRef.current.click();
      trackEvent({
        action: EventNames.Upload_Button_Click,
        location: window.location.href,
        accountId,
        userId,
      });
    }
  };

  const handleCaptureClick = () => {
    trackEvent({
      action: EventNames.Capture_Button_Click,
      location: window.location.href,
      accountId,
      userId,
    });
    setShowRecord(true);
  };

  const logoRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const logoElement = logoRef.current;
    if (!logoElement) return;

    getImageMetadata(logo).then((img) => {
      const dimensions = getImageDimensionsToFit(img, 250, 150);

      logoElement.style.width = `${dimensions.width}px`;
      logoElement.style.height = `${dimensions.height}px`;
    });
  }, [logo]);

  return (
    <>
      <ErrorDialog errorMessage={ErrorsStrings.FileSizeExceeded} isOpen={isErrorAlert} onClose={resetVideo} />
      <Box className={classes.container}>
        <CreateVideoControls
          handleCaptureClick={handleCaptureClick}
          handleInputClick={handleInputClick}
          isButtonsDisabled={isButtonsDisabled}
        />
      </Box>
      <input
        className={['visually-hidden'].join(' ')}
        type="file"
        accept="video/mp4,video/webm,video/quicktime"
        ref={inputRef}
        id="upload-input"
        name="upload-input"
        onChange={onChangeHandler}
      />
    </>
  );
};
