export const getCoverBackground = (
  cover: 'white' | 'branding' | 'black' | undefined,
  brandColor: string | undefined,
) => {
  switch (cover) {
    case 'white':
      return '#FFF';
    case 'branding':
      return brandColor || '#FFF';
    case 'black':
      return '#151515';
    default:
      return '#FFF';
  }
};

export const getRevealBackground = (
  cover: 'branding' | 'splash',
  brandColor: string | undefined,
  splashImage: string | undefined | null,
) => {
  switch (cover) {
    case 'branding':
      return brandColor || '#FFF';
    case 'splash':
      return splashImage || '#151515';
    default:
      return brandColor || '#FFF';
  }
};

export const measureUploadSpeed = async (
  sizeInBytes: number,
): Promise<{
  speedMbps: number;
  uploadTimeSeconds: number;
}> => {
  const SIGNED_URL_ENDPOINT =
    'https://us-east1-quantum-potion-305918.cloudfunctions.net/sv-upload-speed-test-signed-url';

  // Step 1: Request signed URL and headers
  const signedUrlRes = await fetch(SIGNED_URL_ENDPOINT, {
    method: 'POST',
  });

  if (!signedUrlRes.ok) {
    throw new Error('Failed to get signed URL');
  }

  const { url, headers } = await signedUrlRes.json();

  // Step 2: Generate dummy data of the specified size
  const rawData = new Uint8Array(sizeInBytes);
  crypto.getRandomValues(rawData); // Fill with random bytes

  // Step 3: Upload to signed URL, measure speed
  const start = performance.now();

  const uploadRes = await fetch(url, {
    method: 'PUT',
    headers: {
      ...headers,
    },
    body: rawData,
  });

  const end = performance.now();

  if (!uploadRes.ok) {
    throw new Error(`Upload failed with status ${uploadRes.status}`);
  }

  const uploadTimeSeconds = (end - start) / 1000;
  const speedMbps = (sizeInBytes * 8) / (uploadTimeSeconds * 1_000_000);

  return {
    speedMbps,
    uploadTimeSeconds,
  };
};
