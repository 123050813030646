import { eventProps, trackEvent as globalTrackEvent } from '../../../utils/analytics/analytics';
import { useTypedSelector } from '../../store/store';

export type IEventTracker = (props: eventProps) => void;

export const useTrackEvent = () => {
  const { id, name, email, accountId } = useTypedSelector((state) => state.me);
  const { shareStory } = useTypedSelector((state) => state.stories);

  const trackEvent: IEventTracker = (props) => {
    globalTrackEvent({
      location: window.location.href,
      accountId,
      email,
      name,
      videoId: shareStory?.id || null,
      creatorId: id,
      ...props,
    });
  };

  return { trackEvent };
};
