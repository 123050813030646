import { Grid } from '@mui/material';

import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useCrispChat } from '../../../services/crisp/useCrispChat';

import { useTypedSelector } from '../../../store/store';
import { AppRoutes } from '../../constants/routes';
import { useStyles } from './Layout.helper';

interface LayoutProps {
  sidebar?: boolean;
  topNav?: boolean;
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const isWindow = typeof window !== 'undefined';
  const { openCrispChat } = useCrispChat();
  const location = useLocation();

  if (!isWindow) {
    return <div></div>;
  }

  // const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { hideCrispChat } = useTypedSelector((state) => state.ui);
  const classes = useStyles();

  // const isCrispHiddenOnHomePage = signUpFlowActiveTabIndex < (currentCampaign?.welcomeVideoUrl ? 1 : 1);
  const crispConditionsMap = [
    {
      condition: location.pathname === AppRoutes.Home,
      className: hideCrispChat ? classes.hidden : classes.bottomPosition,
    },
    {
      condition: location.pathname.includes('auth'),
      className: classes.bottomPosition,
    },
    {
      condition: location.pathname === AppRoutes.ProfileAccount,
      className: classes.bottomPosition,
    },
    {
      condition: location.pathname === AppRoutes.video,
      className: classes.bottomPosition,
    },
    {
      condition: location.pathname.includes('share'),
      className: classes.bottomPosition,
    },
    {
      condition: location.pathname.includes('socialverse'),
      className: classes.bottomPosition,
    },
  ];
  return (
    <Grid item xs={12} className={classes.container}>
      {children}
      <Grid
        onClick={openCrispChat}
        className={`${classes.crispIcon} ${crispConditionsMap.find(({ condition }) => condition)?.className}`}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        {/* <img src={'/Crisp-chat-2-gray.svg'} alt="crisp" /> */}
      </Grid>
    </Grid>
  );
};
