import { Box, BoxProps, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useVideoControl } from '../../../services/hooks/video-handlers/useVideoControl';
import { PlayVideoIcon } from '../../assets/playerIcons/PlayIcon';
import CCIcon from '../../assets/playerIcons/CCIcon';
import { useVideoPlayerStyles } from './VideoPlayer.styles';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { EventNames } from '../../constants/constants';
import { useThemeLayout } from '../../hooks/useThemeLayout';
import { VideoAppTheme } from '../../../api/models/venue';

export interface VideoPlayerProps extends BoxProps {
  src: string;
  thumbnail?: string;
  width?: string;
  height?: string;
  playButtonSizePx?: number;
  autoplay?: boolean;
  onClick?: () => void;
  captionsUrl?: string;
  showCaption?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  thumbnail,
  width,
  height,
  playButtonSizePx = 68,
  onClick,
  autoplay,
  captionsUrl,
  showCaption,
  ...boxProps
}) => {
  const styles = useVideoPlayerStyles();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { playerState, play, togglePlay, handleOnEnded } = useVideoControl(videoRef);

  const [isTriedToPlay, setIsTriedToPlay] = useState(false);
  const subtitlesRef = useRef<HTMLTrackElement | null>(null);
  const { trackEvent } = useTrackEvent();

  const { isThemeLayoutEnabled, theme } = useThemeLayout();

  const handleClick = () => {
    setIsTriedToPlay(true);
    togglePlay();
    onClick?.();
  };

  const toggleSubtitles = (e: React.MouseEvent) => {
    e.stopPropagation();
    trackEvent({ action: EventNames.SharePageToggleSubtitles });
    const subtitles = subtitlesRef.current?.track;
    if (subtitles && subtitles.mode === 'showing') {
      subtitles.mode = 'hidden';
    } else if (subtitles) {
      subtitles.mode = 'showing';
    }
  };

  useEffect(() => {
    if (autoplay) {
      setIsTriedToPlay(true);
      play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBorderColor = () => {
    if (!isThemeLayoutEnabled) return 'white';

    return theme === VideoAppTheme.Dark ? 'white' : '#E5E5E5';
  };

  return (
    <Box
      width={width}
      height={height}
      borderRadius="16px"
      border={`4px solid ${getBorderColor()}`}
      position="relative"
      overflow="hidden"
      bgcolor="black"
      {...boxProps}
    >
      {captionsUrl && showCaption && (
        <Box
          className={[styles.captionIcon, isTriedToPlay ? styles.captionIconDisplay : ''].join(' ')}
          onClick={toggleSubtitles}
        >
          <CCIcon />
        </Box>
      )}
      <video
        ref={videoRef}
        preload="metadata"
        src={src}
        onClick={handleClick}
        onEnded={handleOnEnded}
        poster={thumbnail}
        playsInline
        crossOrigin="anonymous"
      >
        <source src={''} type="video/mp4" />
        <track
          style={{ background: 'white' }}
          ref={subtitlesRef}
          label="English"
          kind="subtitles"
          srcLang="en"
          src={captionsUrl}
        />
      </video>
      <IconButton
        size="small"
        sx={{
          background: 'rgba(255, 255, 255, 0.5) !important',
          backdropFilter: 'blur(10px)',
          width: `${playButtonSizePx}px`,
          height: `${playButtonSizePx}px`,
          position: 'absolute',
          bottom: `calc(50% - ${playButtonSizePx / 2}px)`,
          right: `calc(50% - ${playButtonSizePx / 2}px)`,
          transition: 'all 0.2s ease-in-out',
          opacity: playerState.isPlaying ? 0 : 1,
        }}
        onClick={handleClick}
      >
        {isTriedToPlay && playerState.isLoading ? (
          <CircularProgress />
        ) : (
          <PlayVideoIcon width={playButtonSizePx / 2} height={playButtonSizePx / 2} />
        )}
      </IconButton>
    </Box>
  );
};
