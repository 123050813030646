import { useEffect, useState } from 'react';

export const useCrispChat = () => {
  const [isCrispOpen, setIsCrispOpen] = useState(false);

  useEffect(() => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['on', 'chat:opened', () => setIsCrispOpen(true)]);
    window.$crisp.push(['on', 'chat:closed', () => setIsCrispOpen(false)]);
  }, []);

  const openCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(['do', 'chat:open']);
  };
  const showCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:show']);
  };

  const hideCrispChatToggle = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:hide']);
  };

  const closeCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:close']);
  };

  const toggleCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:toggle']);
  };

  return {
    openCrispChat,
    closeCrispChat,
    toggleCrispChat,
    isCrispOpen,
    hideCrispChatToggle,
    showCrispChat,
  };
};
