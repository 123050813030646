import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useStyles } from './SignUpFlowPage.styles';
import { NavigationPanel } from '../../common/components/NavigationPanel/NavigationPanel';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { SignUpScreen } from './SignUpScreen/SignUpScreen';
import { setSignUpFlowActiveTabIndex } from '../../store/slices/ui';
import { authWithPartnerToken } from '../../store/slices/me';
import { useQuery } from '../../services/hooks/useQuery';
import WelcomeVideoPage from '../WelcomeVideoPage/WelcomeVideoPage';
import LaunchScreen from '../LaunchScreen/LaunchScreen';

interface Props {
  isDataLoading: boolean;
}

export const SignUpFlowPage: FC<Props> = ({ isDataLoading }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { signUpFlowActiveTabIndex } = useTypedSelector((state) => state.ui);
  const [isRecordVideoScreen, setIsRecordVideoScreen] = useState(false);
  const [isLaunchScreen, setIsLaunchScreen] = useState(false);

  const query = useQuery();
  const partnerToken = query.get('partner-token');

  const {
    account: { id: accountId },
  } = useTypedSelector((state) => state.account);

  useEffect(() => {
    if (partnerToken) {
      dispatch(authWithPartnerToken({ accountId, token: partnerToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (currentCampaign?.welcomeVideoUrl) {
      fetch(currentCampaign?.welcomeVideoUrl);
      fetch(currentCampaign?.welcomeVideoUrl?.replace(/\....$/, '.jpg').replace('/upload/', '/upload/so_0,'));
    }
  }, [currentCampaign?.welcomeVideoUrl]);

  if (isDataLoading || isLaunchScreen) {
    return (
      <Box className={classes.launchScreenWrapper}>
        <LaunchScreen isLoading={isDataLoading} setIsLaunchScreen={setIsLaunchScreen} />
      </Box>
    );
  }
  const handleWelcomeVideoNext = () => {
    dispatch(setSignUpFlowActiveTabIndex(1));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.screenWrapper}>
        {currentCampaign?.welcomeVideoUrl && currentCampaign.showWelcomeVideo && signUpFlowActiveTabIndex === 0 ? (
          <WelcomeVideoPage handleNextStep={handleWelcomeVideoNext} />
        ) : (
          <SignUpScreen isRecordVideoScreen={isRecordVideoScreen} setIsRecordVideoScreen={setIsRecordVideoScreen} />
        )}
      </Box>
      {!isRecordVideoScreen && !isLaunchScreen && (
        <Box className={classes.navPanel}>
          <NavigationPanel activeIndex={signUpFlowActiveTabIndex} />
        </Box>
      )}
    </Box>
  );
};
